import React from 'react';
import { Bars3Icon } from '@icons';
import { useDispatch } from 'react-redux';
import { toggleSideBar } from 'store/slices/hudSlice';
import { useScrollDirection } from '../hooks';
import { AnimatePresence, motion } from 'framer-motion';

export default function SidebarHamburger() {
    const dispatch = useDispatch();
    const scrollDirection = useScrollDirection();

    const handleHamburgerClick = () => {
        dispatch(toggleSideBar());
    };

    return (
        <AnimatePresence>
            {scrollDirection === 'up' && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className="bg-neutral-90 z-40  border border-neutral-70 shadow top-5 left-5 fixed h-10 w-10 aspect-square p-2 rounded-md lg:hidden cursor-pointer"
                    onClick={handleHamburgerClick}
                >
                    <Bars3Icon className="text-neutral-50 m-auto h-full w-full" />
                </motion.div>
            )}
        </AnimatePresence>
    );
}
