import { Column, Table } from '@tanstack/react-table';
import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { UniqueValuesList } from './components';
import { motion } from 'framer-motion';
import { Sheet, SheetContent, SheetDescription, SheetTitle, SheetTrigger } from 'components/ui/sheet';
import { Button } from 'components/ui/button';
import { ArrowLeft } from 'lucide-react';
import UniqueValuesListMobile from './components/UniqueValuesListMobile/UniqueValuesList';
import { Badge } from 'components/ui/badge';

interface FilterPopoverProps<T> {
    table: Table<T>;
}
export default function FilterPopover<T>({ table }: FilterPopoverProps<T>) {
    const columns = table.getAllColumns();
    const [selectedFilterColumn, setSelectedFilterColumn] = React.useState<Column<T> | null>(null);
    const [showFiltersSheet, setShowFiltersSheet] = React.useState(false);

    function handleColumnClick(column: Column<T>) {
        if (selectedFilterColumn?.id === column.id) {
            setSelectedFilterColumn(null);
        } else {
            setSelectedFilterColumn(column);
        }
    }

    // useEffect(() => {
    //     if (selectedFilterColumn) {
    //         if (!table.getColumn(selectedFilterColumn.id)) setSelectedFilterColumn(null);
    //     }
    // }, []);

    const columnFilterVariants = {
        inactive: { borderRadius: '0 0 0 0', marginLeft: 0 },
        active: { borderRadius: '20px 0 0 20px', marginLeft: 7 },
    };

    return (
        <>
            <Popover className={'sm:relative hidden @sm:block'}>
                <Popover.Button
                    className={
                        'py-2 px-2 bg-neutral-80 hover:bg-neutral-70 rounded inline-flex items-center gap-1 text-neutral-50 hover:text-primary-40 transition duration-200'
                    }
                >
                    <FunnelIcon className={'h-5 aspect-square'} />
                </Popover.Button>
                <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className={'absolute z-30 flex text-sm left-0'}>
                        <div
                            className={`w-44 mt-3 bg-neutral-80 shadow-lg rounded h-[380px] overflow-auto relative ${
                                selectedFilterColumn ? 'rounded-r-none' : ''
                            }`}
                        >
                            <h3 className={'text-lg font-semibold p-2 sticky'}>Filters</h3>
                            <div className={''}>
                                {columns.map((column) =>
                                    column.getCanFilter() ? (
                                        <motion.div
                                            key={column.id}
                                            variants={columnFilterVariants}
                                            animate={column.id === selectedFilterColumn?.id ? 'active' : 'inactive'}
                                            transition={{ duration: 0.2, type: 'tween' }}
                                            onClick={() => {
                                                handleColumnClick(column);
                                            }}
                                            className={`py-1 px-2 my-1 flex cursor-pointer justify-between items-center  transition duration-200 ${
                                                column.id === selectedFilterColumn?.id ? 'bg-neutral-70' : 'hover:bg-neutral-70/50  '
                                            }`}
                                        >
                                            <h3 className={'truncate'}>{column.columnDef.header as string}</h3>
                                            <span>{<ChevronRightIcon className={'h-5 aspect-square'} />}</span>
                                        </motion.div>
                                    ) : null
                                )}
                            </div>
                        </div>
                        {selectedFilterColumn && table.getColumn(selectedFilterColumn.id) ? (
                            <UniqueValuesList uniqueValues={selectedFilterColumn.getFacetedUniqueValues()} column={selectedFilterColumn} />
                        ) : null}
                    </Popover.Panel>
                </Transition>
            </Popover>
            <div className="block @sm:hidden">
                <Sheet open={showFiltersSheet}>
                    <SheetTrigger
                        onClick={() => setShowFiltersSheet(true)}
                        className={
                            'py-2 px-2 bg-neutral-80 hover:bg-neutral-70 rounded inline-flex items-center gap-1 text-neutral-50 hover:text-primary-40 transition duration-200'
                        }
                    >
                        <FunnelIcon className={'h-5 aspect-square'} />
                    </SheetTrigger>
                    <SheetContent className="w-full">
                        <button className="absolute" onClick={() => setShowFiltersSheet(false)}>
                            <ArrowLeft className="w-6 h-6" />
                        </button>
                        <SheetTitle className="text-xl text-center">Filter By</SheetTitle>
                        <SheetDescription />
                        <div className=" mt-4 ">
                            {columns.map((column) =>
                                column.getCanFilter() ? (
                                    <motion.div
                                        key={column.id}
                                        onClick={() => {
                                            handleColumnClick(column);
                                        }}
                                        className={`py-2 px-2 rounded my-2 bg-neutral-90 flex cursor-pointer justify-between items-center  transition duration-200 ${
                                            column.id === selectedFilterColumn?.id ? 'bg-neutral-80' : ' '
                                        }`}
                                    >
                                        <div className="flex gap-2 items-center">
                                            <h3 className={'truncate text-lg'}>{column.columnDef.header as string}</h3>
                                            {column.getIsFiltered() ? (
                                                <Badge className="bg-primary-40 h-fit">
                                                    {column.columnDef.meta?.data_type === 'str' ||
                                                    column.columnDef.meta?.data_type === 'list' ||
                                                    column.columnDef.meta?.data_type === 'bool'
                                                        ? (column.getFilterValue() as string[]).length
                                                        : '1'}
                                                </Badge>
                                            ) : null}
                                        </div>
                                        <span>{<ChevronRightIcon className={'h-5 aspect-square'} />}</span>
                                    </motion.div>
                                ) : null
                            )}
                        </div>
                    </SheetContent>
                </Sheet>
                <Sheet open={!!selectedFilterColumn && showFiltersSheet}>
                    <SheetContent className="w-full">
                        <button className="absolute" onClick={() => setSelectedFilterColumn(null)}>
                            <ArrowLeft className="w-6 h-6" />
                        </button>
                        <SheetTitle className="text-xl text-center">{selectedFilterColumn?.columnDef.header as string}</SheetTitle>
                        <SheetDescription />
                        {selectedFilterColumn && table.getColumn(selectedFilterColumn.id) ? (
                            <UniqueValuesListMobile uniqueValues={selectedFilterColumn.getFacetedUniqueValues()} column={selectedFilterColumn} />
                        ) : null}
                    </SheetContent>
                </Sheet>
            </div>
        </>
    );
}
