import { useState, useEffect, useRef } from 'react';

export default function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState('down');
    const [touchStart, setTouchStart] = useState<number | null>(null);
    const lastScrollTop = useRef(0);

    useEffect(() => {
        // For regular scroll
        const handleScroll = () => {
            const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

            if (currentScrollTop > lastScrollTop.current) {
                setScrollDirection('down');
            } else if (currentScrollTop < lastScrollTop.current) {
                setScrollDirection('up');
            }

            lastScrollTop.current = currentScrollTop;
        };

        // For touch events
        const handleTouchStart = (e: TouchEvent) => {
            setTouchStart(e.touches[0].clientY);
        };

        const handleTouchMove = (e: TouchEvent) => {
            if (touchStart === null) return;

            const touchEnd = e.touches[0].clientY;
            const diff = touchStart - touchEnd;

            // You can adjust this threshold based on sensitivity needs
            const threshold = 5;

            if (Math.abs(diff) > threshold) {
                if (diff > 0) {
                    setScrollDirection('down');
                } else {
                    setScrollDirection('up');
                }
            }

            setTouchStart(touchEnd);
        };

        // Add both scroll and touch event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [touchStart]);

    return scrollDirection;
}
