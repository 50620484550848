import { DebouncedInput } from '@modules/common/components/Table/utils';
import { Column } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React from 'react';

interface StringFiltersProps<T> {
    handleFilterClick: (value: string) => void;
    isFilterApplied: (value: string) => boolean;
    uniqueValues: Map<Array<string>, number>;
    column: Column<T>;
}

export default function ListFilters<T>({ handleFilterClick, isFilterApplied, uniqueValues, column }: StringFiltersProps<T>) {
    const [uniqueValuesList, setUniqueValuesList] = React.useState<string[]>([]);
    const [query, setQuery] = React.useState('');
    const parentRef = React.useRef(null);

    const mutatedUniqueValues = Array.from(
        new Set(
            Array.from(uniqueValues)
                .map(([value, count]) => value)
                .flat(1)
        )
    );
    // console.log(mutatedUniqueValues)

    const virtualizedList = useVirtualizer({
        count: uniqueValuesList.length,
        getScrollElement: () => parentRef.current,
        overscan: 10,
        estimateSize: () => 34,
    });

    function sortMapByKey(map: string[]) {
        return Array.from(map).sort((a, b) => sortFilterValues(a, b, column));
    }

    function filterList(filterValue: string | number) {
        let filteredList: string[] = [];
        if (typeof filterValue === 'string' && filterValue.length > 0) {
            filteredList = mutatedUniqueValues.filter((value) => (value === null ? false : value.toLowerCase().includes(filterValue.toLowerCase())));
        } else filteredList = sortMapByKey(mutatedUniqueValues);
        setUniqueValuesList(filteredList);
    }

    return (
        <>
            <div className={' z-20 '}>
                <DebouncedInput
                    debounce={200}
                    type="text"
                    placeholder={'Search...'}
                    value={''}
                    onChange={filterList}
                    className={'bg-neutral-90 border-neutral-70 border rounded px-2 w-full py-1 '}
                />
            </div>
            <div className={'overflow-auto mt-2 pr-1'} ref={parentRef} style={{ maxHeight: window.outerHeight - 275 }}>
                <ul className={'relative'} style={{ height: `${virtualizedList.getTotalSize()}px` }}>
                    {virtualizedList.getVirtualItems().map((virtualItem, index) => {
                        const value = uniqueValuesList[virtualItem.index];
                        return (
                            <button
                                key={value as string}
                                className={`py-1 px-2 flex w-full my-1 gap-2 justify-between items-center rounded absolute bg-neutral-90
									cursor-pointer transition duration-200  ${isFilterApplied(value) ? 'bg-primary-40 text-neutral-95' : ''}`}
                                onClick={() => handleFilterClick(value)}
                                style={{
                                    top: `${virtualItem.start}px`,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                }}
                            >
                                <h3 className={'truncate'}>{value ?? '—'}</h3>
                            </button>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

function sortFilterValues<T>(aValue: string | number | boolean | string, bValue: string, column: Column<T>) {
    const columnId = column.id;
    const originalColumn = column.columnDef.meta;
    if (!originalColumn) return 0;
    if (
        originalColumn.data_type === 'num' ||
        originalColumn.data_type === 'int' ||
        originalColumn.data_type === 'float' ||
        originalColumn.key === 'Risk_Factor'
    ) {
        const a = Number(aValue);
        const b = Number(bValue);
        return a < b ? -1 : a > b ? 1 : 0;
    } else if (originalColumn.key === 'Letter_Grade') {
        const grade = ['F', 'D-', 'D', 'D+', 'P', 'C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-', 'A', 'A+'];
        const a = grade.indexOf(aValue as string);
        const b = grade.indexOf(bValue as string);
        return a < b ? -1 : a > b ? 1 : 0;
    } else if (originalColumn.key === 'Grade' || originalColumn.key === 'Grade_Level') {
        const grade = [
            'KG',
            'K',
            '01',
            '1',
            '02',
            '2',
            '03',
            '3',
            '04',
            '4',
            '05',
            '5',
            '06',
            '6',
            '07',
            '7',
            '08',
            '8',
            '09',
            '9',
            '10',
            '11',
            '12',
        ];
        const a = grade.indexOf(aValue as string);
        const b = grade.indexOf(bValue as string);
        return a < b ? -1 : a > b ? 1 : 0;
    } else if (originalColumn.data_type === 'date') {
        const a = new Date(aValue as string).getTime();
        const b = new Date(bValue as string).getTime();
        return a < b ? -1 : a > b ? 1 : 0;
    } else if (originalColumn.data_type === 'bool') {
        const a = aValue as unknown as boolean;
        const b = bValue as unknown as boolean;
        return a === b ? 0 : a ? 1 : -1;
    } else if (originalColumn.data_type === 'str' || originalColumn.data_type === 'list') {
        const a = aValue as string;
        const b = bValue as string;
        if (a === null) return b === null ? 0 : -1;
        if (b === null) return 1;
        return a.localeCompare(b);
    }

    return 0;
}
